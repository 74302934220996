<template>
  <div>
    <index></index>
    <nei></nei>
    <router-view></router-view>
    <bottom></bottom>
  </div>
</template>

<script>
import Dao from "../components/common/Dao.vue";
import Bottom from "../components/common/Bottom.vue";
import Index from "../components/shehui/Index";
import Nei from "../components/shehui/Nei";
export default {
  name: "She",
  components: {
    Bottom,
    Dao,
    Index,
    Nei,
  },
};
</script>

<style scoped></style>
