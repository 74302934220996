// // 首先导入接口的请求方式，这个就是从require.js里边导出的两个请求方法post,get
import { posts, gets } from "./require";
// // 定义接口方法
// // 接口的名称劲量和地址最后的名称一样
// //post 提交形式
// // export function postsType(data) {
// //     return posts({
// //         //接口的地址
// //         url: '/Manage/Distribution/postsType',
// //         //接口需要的数据
// //         params: data,
// //         //接口的数据请求类型
// //         // formData：把数据组装成from表单提交形式
// //         // formUrlencoded： 把数据通过qs.stringify转换成字符串形式提交
// //         //如果不传直接把元数据传过去
// //         type: 'formData'
// //     });
// // }
// // //get 提交形式
// // export function getsType(data) {
// //     return gets({
// //         //接口的地址
// //         url: '/Manage/Distribution/getsType',
// //         //接口需要的数据
// //         params: data
// //     });
// // }
// let api = "/api";
let api = ' http://dwj.test.jianong.com';
export function getSociology(data) {
  return posts({
    //接口的地址
    url: `${api}/index.php/index/index/getSociology`,
    //接口需要的数据
    params:data,
  });
}

// // export function getScience(data) {
// //   return gets({
// //     //接口的地址
// //     url: `${api}/index.php/index/index/getScience`,
// //     //接口需要的数据
// //     params: data
// //   });
// // }
