<template>
  <div>
    <div class="img">
      <img src="./img/banner 拷贝.png" alt="">
    </div>
    <div class="zou"  >
      <div class="han">社会责任</div>
      <div class="ying">
        Social
        responsibility
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Index",
    watch:{
      $route:{
        deep:true,
        immediate:true,
        handler(newV,olV) {
          // console.log(this.active)
          this.active = newV.name;
          // console.log(newV,"hello大家好")
          // console.log(this.active,"??????")
        }
      }
    },
  }
</script>

<style scoped>
  .img img{
    width: 100%;
    height: 48.6891vh;
  }
  .zou{
    width: 11.1979vw;
    height: 12.9551vh;
    opacity: 0.8;
    background-color: #d7000f;
    position: absolute;
    margin-left: 5.5625vw;
    margin-top: -8.4633vh;
  }
  .han{
    width: 10.7083vw;
    height: 1.4063vw;
    font-size: 1.5625vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    margin-top:0.6771vw ;
    margin-left: 0.8854vw;
  }
  .ying{
    width: 10.8125vw;
    height: 1.1979vw;
    font-size: 1.45vw;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    text-align: left;
    color: #ffffff;
    margin-left: 0.8854vw;
    margin-top: 0.6vw;
    line-height: 1vw;
  }
</style>